@tailwind base;
@tailwind components;
@tailwind utilities;

body {

    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
    scroll-behavior: auto;

}